import { SUBSCRIBE_LINKS } from 'config';
import React, { useState, useEffect } from 'react';
import { Swiper, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';

Swiper.use([Navigation]);

const Subscribe = (props: any) => {
    const { showSubscribe, setShowSubscribe } = props;

    useEffect(() => {
        const formSwiper = new Swiper(
            '#popup-subscribe .form-swiper__container',
            {
                slidesPerView: 8,
                slidesPerGroup: 1,

                navigation: {
                    nextEl: '.form-swiper__navigation_next',
                    prevEl: '.form-swiper__navigation_prev',
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 6,
                        slidesPerGroup: 1,
                        spaceBetween: 10,
                    },

                    // when window width is >= 768px
                    768: {
                        slidesPerView: 10,
                        slidesPerGroup: 1,
                    },
                },
            }
        );
    }, [showSubscribe]);
    return (
        <div
            id={`popup-subscribe`}
            className={`popup ${showSubscribe ? 'active' : ''}`}
        >
            <div
                className="popup-shadow"
                onClick={e => setShowSubscribe(!showSubscribe)}
            ></div>
            <div className="box">
                <div className="form-wrapper active ">
                    <div
                        className="close js-close"
                        onClick={e => setShowSubscribe(!showSubscribe)}
                    ></div>

                    <div className="form-container">
                        <div className="form-title">
                            <p>Подписаться</p>
                        </div>

                        <div className="form-description text">
                            {/* <p>Запись подкаста «{attributes.Title}»</p> */}
                        </div>
                        <div className="form-swiper">
                            <div className="form-swiper__container swiper">
                                <div className="form-swiper__wrapper swiper-wrapper subscribe">
                                    <a
                                        href={SUBSCRIBE_LINKS.APPLE}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/service-1.svg"
                                            alt="apple"
                                        />
                                    </a>
                                    <a
                                        href={SUBSCRIBE_LINKS.VK}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/service-3.svg"
                                            alt="ВКонтакте"
                                        />
                                    </a>
                                    <a
                                        href={SUBSCRIBE_LINKS.YOUTUBE}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/sp_yt.svg"
                                            alt="YouTube"
                                        />
                                    </a>
                                    <a
                                        href={SUBSCRIBE_LINKS.SPOTIFY}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/service-5.svg"
                                            alt="Spotify"
                                        />
                                    </a>
                                    <a
                                        href={SUBSCRIBE_LINKS.CASTRO}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/service-6.svg"
                                            alt="Castro"
                                        />
                                    </a>
                                    <a
                                        href={SUBSCRIBE_LINKS.CASTBOX}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/service-7.svg"
                                            alt="Castbox"
                                        />
                                    </a>
                                    <a
                                        href={SUBSCRIBE_LINKS.POCKETCAST}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/service-8.svg"
                                            alt="Pocket Casts"
                                        />
                                    </a>
                                    <a
                                        href={SUBSCRIBE_LINKS.ZVUK}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/sp_zvuk.svg"
                                            alt="ЗВУК"
                                        />
                                    </a>
                                    <a
                                        href={SUBSCRIBE_LINKS.GOOGLE}
                                        className="form-swiper__item swiper-slide"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="/img/service-4.svg"
                                            alt="Google Podcasts"
                                        />
                                    </a>
                                </div>
                            </div>

                            <div className="form-swiper__navigation form-swiper__navigation_prev swiper-button-prev">
                                <svg
                                    width="14"
                                    height="24"
                                    viewBox="0 0 14 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="17.094"
                                        height="2.56409"
                                        transform="matrix(0.712175 0.702002 0.712175 -0.702002 0 12)"
                                        fill="white"
                                    />
                                    <rect
                                        width="17.094"
                                        height="2.56409"
                                        transform="matrix(0.712175 -0.702002 -0.712175 -0.702002 1.82617 13.8)"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                            <div className="form-swiper__navigation form-swiper__navigation_next swiper-button-next">
                                <svg
                                    width="14"
                                    height="24"
                                    viewBox="0 0 14 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="17.094"
                                        height="2.56409"
                                        transform="matrix(0.712175 0.702002 0.712175 -0.702002 0 12)"
                                        fill="white"
                                    />
                                    <rect
                                        width="17.094"
                                        height="2.56409"
                                        transform="matrix(0.712175 -0.702002 -0.712175 -0.702002 1.82617 13.8)"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscribe;
