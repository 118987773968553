import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shareLink, apiUrl, trackBaseUrl } from 'config';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import { State } from '../../types';
// import { getPodcasts, getPodcastsIdle, getPodcastsClear } from "./actions";
import {
    getPodcasts,
    getPodcastsIdle,
    getPodcastsClear,
} from 'store/ducks/podcasts/actions';
import Item from '../Item/Item';
import { Swiper, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
Swiper.use([Navigation]);
// import '../../modules/music-yandex.min.js';

function Podcasts() {

    let { id } = useParams<{ id: string }>();
    
    const dispatch = useDispatch();

    const { status, podcasts, meta } = useSelector(
        (state: State) => state.Podcasts
    );

    const [openPopup, setOpenPopup] = useState(false);

    // const handleShowMore = (e: any) => {
    //     e.preventDefault();
    //     dispatch(
    //         getPodcasts({
    //             start: podcasts.length + 1,
    //         })
    //     );
    // };

    // useEffect(() => {
    //     if (status == 'IDLE') {
    //         dispatch(
    //             getPodcasts({
    //                 start: 1,
    //             })
    //         );
    //     }
    // }, [dispatch]);

    // useEffect(() => {
    //     if (status == 'IDLE') {
    //         dispatch(
    //             getPodcasts({
    //                 slug: id,
    //             })
    //         );
    //     }
    // }, [dispatch, id]);

    useEffect(() => {
        const popupBlock = document.querySelectorAll('.js-popup-block');
       
        if (popupBlock) {
            popupBlock.forEach(item => {
                const btnPopup = item.querySelector('.js-btn-popup');
                const itemPopup = item.querySelector('.section-3__popup');

                if (btnPopup) {
                    btnPopup.addEventListener('click', function (element) {
                        
                        itemPopup.classList.toggle('show');
                        item.classList.toggle('active');
                    });
                }
            });

            document.addEventListener('click', function (element) {
                const itemPopup = document.querySelectorAll(
                    '.section-3__popup'
                );

                itemPopup.forEach(popup => {
                    if (
                        (element.target as Element).classList.contains(
                            'js-close'
                        ) ||
                        (element.target as Element).classList.contains(
                            'popup-shadow'
                        )
                    ) {
                        popup.classList.remove('show');
                    }
                });
            });
        }
    }, [podcasts]);

    useEffect(() => {
        podcasts.forEach((item, index) => {
            let id = item.id;

            /* сохраняем текстовое поле в переменную text */
            const text = document.querySelector<HTMLInputElement>(
                '#popup-' + id + ' .form-input__item'
            );
            /* сохраняем кнопку в переменную btn */
            const btn = document.querySelector<HTMLElement>(
                '#popup-' + id + ' .form-input__img'
            );

            /* вызываем функцию при нажатии на кнопку */
            if (btn) {
                btn.onclick = function () {
                    text.select();
                    document.execCommand('copy');
                    btn.classList.add('copied');
                    setTimeout(() => {
                        btn.classList.remove('copied');
                    }, 1000);
                };
            }

            const formSwiper = new Swiper(
                '#popup-' + id + ' .form-swiper__container',
                {
                    slidesPerView: 9,
                    slidesPerGroup: 1,

                    navigation: {
                        nextEl: '.form-swiper__navigation_next',
                        prevEl: '.form-swiper__navigation_prev',
                    },
                    breakpoints: {
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 6,
                            slidesPerGroup: 1,
                            spaceBetween: 0,
                        },

                        // when window width is >= 768px
                        768: {
                            slidesPerView: 6,
                            slidesPerGroup: 1,
                            spaceBetween: 0,
                        },
                    },
                }
            );

            const strings = document.querySelectorAll(
                '.section-3__item-description p'
            );

            //обрезаем текст в описании подкаста
            strings.forEach(text => {
                let length = 195;
                let string = text.innerHTML;
                let trimmedString =
                    string.length > length
                        ? string.substring(0, length - 3) + '...'
                        : string;

                text.innerHTML = trimmedString;
            });
        });
    }, [podcasts]);

    return (
        <>
             <div className="section-3__wrapper">
                    {podcasts.map(podcast => (
                        <Item {...podcast} key={podcast.id} />
                    ))}
            </div>
        </>
    );
}

export default Podcasts;
