import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../types';
// import { getPodcasts, getPodcastsIdle } from 'store/ducks/podcasts/actions';
import { Link } from 'react-router-dom';
import { shareLink, apiUrl, trackBaseUrl } from '../../config';
import { Swiper, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import Moment from 'react-moment';
import 'moment/locale/ru';
import ReactMarkdown from 'react-markdown';
Swiper.use([Navigation]);
// import '../../modules/music-yandex.min.js';
const Item = (props: any) => {
    const { id, status, podcasts, attributes } = props;

    const {
        Title,
        Thumbnail,
        Date,
        Description,
        File,
        File_url,
        Season,
        Slug,
    } = attributes;
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        const popupBlock = document.querySelectorAll('.js-popup-block');
        if (popupBlock) {
            popupBlock.forEach(item => {
                const btnPopup = item.querySelector('.js-btn-popup');
                const itemPopup = item.querySelector('.section-3__popup');

                if (btnPopup) {
                    btnPopup.addEventListener('click', function (element) {
                        itemPopup.classList.add('show');
                        item.classList.add('active');
                    });
                }
            });

            document.addEventListener('click', function (element) {
                const itemPopup = document.querySelectorAll(
                    '.section-3__popup'
                );

                itemPopup.forEach(popup => {
                    if (
                        (element.target as Element).classList.contains(
                            'js-close'
                        ) ||
                        (element.target as Element).classList.contains(
                            'popup-shadow'
                        )
                    ) {
                        popup.classList.remove('show');
                    }
                });
            });
        }
    }, [podcasts]);

    return (
        <>
            <div className="section-3__block js-popup-block">
                <div className="section-3__block js-popup-block" key={id}>
                    <div className="row">
                        <div className="section-3__img col-3 col-md-3">
                            {attributes.Thumbnail.data && (
                                <img
                                    src={`${apiUrl}${attributes.Thumbnail.data.attributes.url}`}
                                    alt=""
                                />
                            )}
                            <div className="section-3__img-title">
                                {attributes.Season}
                            </div>
                        </div>

                        <div
                            id={`player__item-${id}`}
                            className="section-3__item player__item col-9 col-md-9"
                        >
                            <div className="section-3__item-info player__item-info">
                                <p className="section-3__item-info_date player__item-info_date">
                                    <Moment locale="ru" format="D MMMM YYYY">
                                        {attributes.Date}
                                    </Moment>
                                </p>
                                <p className="section-3__item-info_time player__item-info_time">
                                    01:01:26
                                </p>
                            </div>
                            <div className="section-3__item-title player__item-title">
                                <h4>
                                    <Link to={`/${attributes.Slug}`}>
                                        {attributes.Title}
                                        <svg
                                            width="30"
                                            height="14"
                                            viewBox="0 0 30 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M25.7106 5.93112L21.9119 2.81895L21.9119 -6.00131e-07L30 6.02876L30 7.97123L21.9119 14L21.9119 11.181L25.6092 8.15193L-2.55627e-07 8.15193L-3.52702e-07 5.93112L25.7106 5.93112Z"
                                                fill="#23D1AE"
                                            />
                                        </svg>
                                    </Link>
                                </h4>
                            </div>

                            <div className="section-3__item-description player__item-description">
                                <ReactMarkdown>
                                    {attributes.Description}
                                </ReactMarkdown>
                            </div>

                            <div className="section-3__item-links player__item-links">
                                <div
                                    className={`section-3__item-links_share section-3__item-link player__item-link js-open-popup`}
                                    onClick={e => setOpenPopup(!openPopup)}
                                >
                                    <div className="section-3__item-link_img">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.8 4H4V16H16V11.2"
                                                stroke="#AEAEAE"
                                                strokeWidth="1.5"
                                            />
                                            <path
                                                d="M7 13L16 4M16 4H10.8571M16 4V9.14286"
                                                stroke="#AEAEAE"
                                                strokeWidth="1.5"
                                            />
                                        </svg>
                                    </div>

                                    <span className="section-3__item-links_share-text player__item-links_share-text">
                                        Поделиться
                                    </span>
                                </div>

                                <a
                                    href={
                                        (attributes.File.data &&
                                            trackBaseUrl +
                                                attributes.File.data.attributes
                                                    .url) ||
                                        attributes.File_url
                                    }
                                    download
                                    className="section-3__item-links_download section-3__item-link player__item-link player__item-links_download"
                                >
                                    <div className="section-3__item-link_img">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M17.1004 13.8369V18.1H2.88989V13.8369H4.31095V16.679H15.6794V13.8369H17.1004ZM14.0523 10.9308L13.0433 9.92187L10.7057 12.2666V1.90002H9.28466V12.2666L6.94702 9.92187L5.93808 10.9308L9.99518 14.9808L14.0523 10.9308Z"
                                                fill="#AEAEAE"
                                            />
                                        </svg>
                                    </div>

                                    <span className="section-3__item-links_download-text player__item-links_download-text">
                                        Скачать
                                    </span>
                                </a>

                                <div className="section-3__item-links_player section-3__item-link player__item-link player__item-links_player">
                                    <div className="section-3__item-link_img">
                                        <svg
                                            className="play-button"
                                            width="15"
                                            height="18"
                                            viewBox="0 0 15 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14.5342 9.33803L0.72596 17.7887L0.725961 0.887328L14.5342 9.33803Z"
                                                fill="#7EFF33"
                                            />
                                        </svg>
                                    </div>

                                    <span className="section-3__item-links_share-text player__item-links_share-text">
                                        Слушать
                                    </span>
                                </div>
                            </div>

                            <div className="section-3__item-player player__item-player">
                                <div
                                    className="player"
                                    data-url={
                                        (attributes.File.data &&
                                            trackBaseUrl +
                                                attributes.File.data.attributes
                                                    .url) ||
                                        attributes.File_url
                                    }
                                >
                                    <div className="player-info">
                                        <div className="player-info__icon player-info__favorite">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.9911 7.03878C9.82613 4.77698 8.10004 3 6 3C3.79086 3 2 4.96861 2 7.39702C2 8.65685 2.482 9.79294 3.25435 10.5946L9.9911 18L16.5792 10.758C17.4481 9.95144 18 8.74507 18 7.39702C18 4.96861 16.2091 3 14 3C11.8973 3 10.1524 4.77295 9.9911 7.03878Z"
                                                    stroke="#AEAEAE"
                                                    strokeWidth="1.5"
                                                />
                                            </svg>
                                        </div>
                                        <div className="player-info__title">
                                            {attributes.Title}
                                        </div>
                                        <div
                                            className="player-info__icon player-info__share"
                                            onClick={e =>
                                                setOpenPopup(!openPopup)
                                            }
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.8 4H4V16H16V11.2"
                                                    stroke="#AEAEAE"
                                                    strokeWidth="1.5"
                                                ></path>
                                                <path
                                                    d="M7 13L16 4M16 4H10.8571M16 4V9.14286"
                                                    stroke="#AEAEAE"
                                                    strokeWidth="1.5"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="controls">
                                        <button className="controls_play">
                                            <svg
                                                className="pause-button"
                                                width="14"
                                                height="16"
                                                viewBox="0 0 14 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M5.22768 0.225464H0.906738V15.7748H5.22768V0.225464ZM13.0868 0.225464H8.76585V15.7748H13.0868V0.225464Z"
                                                    fill="#7EFF33"
                                                />
                                            </svg>

                                            <svg
                                                className="play-button"
                                                width="15"
                                                height="18"
                                                viewBox="0 0 15 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.5342 9.33803L0.72596 17.7887L0.725961 0.887328L14.5342 9.33803Z"
                                                    fill="#7EFF33"
                                                />
                                            </svg>
                                        </button>
                                    </div>

                                    <div className="progress">
                                        <div className="progress_position">
                                            00:00:00
                                        </div>
                                        <div className="progress_loaded"></div>
                                        <div
                                            className="progress_current"
                                            data-played
                                        ></div>
                                        <div className="progress_duration">
                                            00:00:00
                                        </div>
                                    </div>

                                    <div className="overlay"></div>
                                </div>
                            </div>

                            <div className="section-3__item-show js-btn-popup">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.9925 11.9999L12.0075 11.9999M6.00001 11.9999L6.01499 11.9999M17.985 11.9999L18 11.9999"
                                        stroke="white"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                            </div>

                            <div className="section-3__popup player__item col-12">
                                <div className="popup-shadow"></div>
                                <div className="close js-close"></div>
                                <div className="popup-container">
                                    <div className="section-3__popup-info player__item-info">
                                        <p className="section-3__popup-info_date player__item-info_date">
                                            <Moment
                                                locale="ru"
                                                format="D MMMM YYYY"
                                            >
                                                {attributes.Date}
                                            </Moment>
                                        </p>
                                        <p className="section-3__popup-info_time player__item-info_time">
                                            01:01:26
                                        </p>
                                    </div>
                                    <div className="section-3__popup-title player__item-title">
                                        <h4>
                                            <Link to={`/${attributes.Slug}`}>
                                                {attributes.Title}
                                            </Link>
                                        </h4>
                                    </div>
                                    <div className="section-3__popup-description player__item-description">
                                        <ReactMarkdown>
                                            {attributes.Description}
                                        </ReactMarkdown>
                                    </div>

                                    <div className="section-3__popup-links player__item-links">
                                        <div
                                            className={`section-3__item-links_share section-3__popup-link player__item-link js-open-popup`}
                                            onClick={e =>
                                                setOpenPopup(!openPopup)
                                            }
                                        >
                                            <div className="section-3__popup-link_img">
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8.8 4H4V16H16V11.2"
                                                        stroke="#AEAEAE"
                                                        strokeWidth="1.5"
                                                    />
                                                    <path
                                                        d="M7 13L16 4M16 4H10.8571M16 4V9.14286"
                                                        stroke="#AEAEAE"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </div>

                                            <span className="section-3__popup-links_share-text player__item-links_share-text">
                                                Поделиться
                                            </span>
                                        </div>

                                        <a
                                            href={
                                                (attributes.File.data &&
                                                    trackBaseUrl +
                                                        attributes.File.data
                                                            .attributes.url) ||
                                                attributes.File_url
                                            }
                                            download
                                            className="section-3__popup-links_download section-3__popup-link player__item-link player__item-links_download"
                                        >
                                            <div className="section-3__popup-link_img">
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M17.1004 13.8369V18.1H2.88989V13.8369H4.31095V16.679H15.6794V13.8369H17.1004ZM14.0523 10.9308L13.0433 9.92187L10.7057 12.2666V1.90002H9.28466V12.2666L6.94702 9.92187L5.93808 10.9308L9.99518 14.9808L14.0523 10.9308Z"
                                                        fill="#AEAEAE"
                                                    />
                                                </svg>
                                            </div>

                                            <span className="section-3__popup-links_download-text player__item-links_download-text">
                                                Скачать
                                            </span>
                                        </a>

                                        <div className="section-3__popup-links_player section-3__popup-link player__item-link player__item-links_player js-player-mobile">
                                            <div className="section-3__popup-link_img">
                                                <svg
                                                    className="pause-button"
                                                    width="14"
                                                    height="16"
                                                    viewBox="0 0 14 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M5.22768 0.225464H0.906738V15.7748H5.22768V0.225464ZM13.0868 0.225464H8.76585V15.7748H13.0868V0.225464Z"
                                                        fill="#7EFF33"
                                                    />
                                                </svg>

                                                <svg
                                                    className="play-button"
                                                    width="15"
                                                    height="18"
                                                    viewBox="0 0 15 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.5342 9.33803L0.72596 17.7887L0.725961 0.887328L14.5342 9.33803Z"
                                                        fill="#7EFF33"
                                                    />
                                                </svg>
                                            </div>

                                            <span className="section-3__popup-links_share-text player__item-links_share-text">
                                                Слушать
                                            </span>
                                        </div>
                                    </div>

                                    <div className="section-3__popup-player player__item-player">
                                        <div
                                            className="player"
                                            data-url={
                                                (attributes.File.data &&
                                                    trackBaseUrl +
                                                        attributes.File.data
                                                            .attributes.url) ||
                                                attributes.File_url
                                            }
                                        >
                                            <div className="controls">
                                                <button className="controls_play">
                                                    <svg
                                                        className="pause-button"
                                                        width="14"
                                                        height="16"
                                                        viewBox="0 0 14 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M5.22768 0.225464H0.906738V15.7748H5.22768V0.225464ZM13.0868 0.225464H8.76585V15.7748H13.0868V0.225464Z"
                                                            fill="#7EFF33"
                                                        />
                                                    </svg>

                                                    <svg
                                                        className="play-button"
                                                        width="15"
                                                        height="18"
                                                        viewBox="0 0 15 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M14.5342 9.33803L0.72596 17.7887L0.725961 0.887328L14.5342 9.33803Z"
                                                            fill="#7EFF33"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>

                                            <div className="progress">
                                                <div className="progress_position">
                                                    00:00:00
                                                </div>
                                                <div className="progress_loaded"></div>
                                                <div
                                                    className="progress_current"
                                                    data-played
                                                ></div>
                                                <div className="progress_duration">
                                                    00:00:00
                                                </div>
                                            </div>

                                            <div className="overlay"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id={`popup-${id}`}
                        className={`popup ${openPopup ? 'active' : ''}`}
                    >
                        <div
                            className="popup-shadow"
                            onClick={e => setOpenPopup(!openPopup)}
                        ></div>
                        <div className="box">
                            <div className="form-wrapper active">
                                <div
                                    className="close js-close"
                                    onClick={e => setOpenPopup(!openPopup)}
                                ></div>

                                <div className="form-container">
                                    <div className="form-title">
                                        <p>Поделиться</p>
                                    </div>

                                    <div className="form-description text">
                                        <p>
                                            Запись подкаста «{attributes.Title}»
                                        </p>
                                    </div>
                                    <div className="form-swiper">
                                        <div className="form-swiper__container swiper">
                                            <div className="form-swiper__wrapper swiper-wrapper">
                                                <a
                                                    href={
                                                        'mailto:?subject=' +
                                                        attributes.Title +
                                                        '&body=' +
                                                        `${shareLink}/${attributes.Slug}`
                                                    }
                                                    className="form-swiper__item swiper-slide"
                                                    tooltip="Email"
                                                    flow="up"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src="/img/share-icon-1.svg"
                                                        alt="Email"
                                                    />
                                                </a>
                                                <a
                                                    href={
                                                        'http://vk.com/share.php?url=' +
                                                        `${shareLink}/${attributes.Slug}` +
                                                        '&title=' +
                                                        attributes.Title
                                                    }
                                                    className="form-swiper__item swiper-slide"
                                                    tooltip="VK"
                                                    flow="up"
                                                    target="_blank"
                                                    rel="nofollow"
                                                >
                                                    <img
                                                        src="/img/share-icon-2.svg"
                                                        alt="VK"
                                                    />
                                                </a>
                                                <a
                                                    href={
                                                        'https://connect.ok.ru/offer?url=' +
                                                        `${shareLink}/${attributes.Slug}` +
                                                        '&title=' +
                                                        attributes.Title
                                                    }
                                                    className="form-swiper__item swiper-slide"
                                                    tooltip="OK"
                                                    flow="up"
                                                    target="_blank"
                                                    rel="nofollow"
                                                >
                                                    <img
                                                        src="/img/share-icon-3.svg"
                                                        alt="OK"
                                                    />
                                                </a>
                                                <a
                                                    href={
                                                        'https://wa.me/?text=' +
                                                        `${shareLink}/${attributes.Slug}`
                                                    }
                                                    className="form-swiper__item swiper-slide"
                                                    tooltip="WhatsApp"
                                                    flow="up"
                                                >
                                                    <img
                                                        src="/img/share-icon-4.svg"
                                                        alt="WhatsApp"
                                                    />
                                                </a>
                                                <a
                                                    href={
                                                        'https://t.me/share/url?url=' +
                                                        `${shareLink}/${attributes.Slug}` +
                                                        '&text=' +
                                                        attributes.Title
                                                    }
                                                    className="form-swiper__item swiper-slide"
                                                    tooltip="Telegram"
                                                    flow="up"
                                                    target="_blank"
                                                    rel="nofollow noreferrer noopener"
                                                >
                                                    <img
                                                        src="/img/share-icon-5.svg"
                                                        alt="Telegram"
                                                    />
                                                </a>

                                            </div>
                                        </div>

                                        <div className="form-swiper__navigation form-swiper__navigation_prev swiper-button-prev">
                                            <svg
                                                width="14"
                                                height="24"
                                                viewBox="0 0 14 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="17.094"
                                                    height="2.56409"
                                                    transform="matrix(0.712175 0.702002 0.712175 -0.702002 0 12)"
                                                    fill="white"
                                                />
                                                <rect
                                                    width="17.094"
                                                    height="2.56409"
                                                    transform="matrix(0.712175 -0.702002 -0.712175 -0.702002 1.82617 13.8)"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                        <div className="form-swiper__navigation form-swiper__navigation_next swiper-button-next">
                                            <svg
                                                width="14"
                                                height="24"
                                                viewBox="0 0 14 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="17.094"
                                                    height="2.56409"
                                                    transform="matrix(0.712175 0.702002 0.712175 -0.702002 0 12)"
                                                    fill="white"
                                                />
                                                <rect
                                                    width="17.094"
                                                    height="2.56409"
                                                    transform="matrix(0.712175 -0.702002 -0.712175 -0.702002 1.82617 13.8)"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="form-input">
                                        <input
                                            className="form-input__item"
                                            type="text"
                                            defaultValue={`${shareLink}/${attributes.Slug}`}
                                            readOnly
                                        />

                                        <div className="form-input__img">
                                            <svg
                                                className="form-input__img-copy"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M11.5 3.5H4.5V12.5H7V7.5V6H8.5H11.5V3.5ZM13 6H15.5H17V7.5V16.5V18H15.5H8.5H7V16.5V14H4.5H3V12.5V3.5V2H4.5H11.5H13V3.5V6ZM8.5 7.5H15.5V16.5H8.5V7.5Z"
                                                    fill="white"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M11.5 3.5V6H7V12.5H4.5V3.5H11.5ZM13 6V2H3V14H7V18H17V6H13ZM8.5 7.5V16.5H15.5V7.5H8.5Z"
                                                    fill="white"
                                                />
                                            </svg>

                                            <svg
                                                className="form-input__img-copied"
                                                fill="#FFFFFF"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 32 32"
                                                width="32px"
                                                height="32px"
                                            >
                                                <path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Item;
